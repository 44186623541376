<template>
    <app-wrapper>
        <template v-slot:child>
            <!--Raw Material -->
            <div class="w-90 center">
                <div class="pt4">
                    <div>
                        <div class="pb3 pb0-l f3 b lh-copy">Create Product</div>
                    </div>
                </div>
            </div>
            <!--Raw Material -->

            <!-- summary all product, draft archieve -->
            <div class="w-90 center pt4">
                <div class="flex scrollmenux">
                    <div class="mr4">
                        <router-link :to="{ name: 'CreateRawMaterial' }" exact-active-class="active-menu">
                            Raw Material
                        </router-link>
                    </div>
                    <div v-if="state.wipStatus" class="mr4">
                        <router-link :to="{ name: 'CreateWorkInProgress' }" exact-active-class="active-menu">
                            Work in Progress
                        </router-link>
                    </div>
                    <!-- <div class="mr4 ">
                        <router-link :to="{ name: 'CreateFinishedProduct' }" exact-active-class="active-menu">
                            Finished Product
                        </router-link>
                    </div> -->
                </div>
            </div>
            <!-- summary all product, draft archieve -->

            <router-view />
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'

export default {
    name: 'Parent',
    components: { AppWrapper },

    setup() {
        const store = useStore()

        const initialState = () => ({
            wipStatus: false
        })
        const state = reactive(initialState())

        const createProductDropDown = () => {
            document.getElementById('profile-dropdown').classList.toggle('show')
        }

        onMounted(() => {
            // store.dispatch('Reporting/getReportingProfits')
            store.dispatch('Settings/getAccountSettings').then(data => {
                if (data.status) {
                    state.wipStatus = data?.data?.wipStatus || false
                }
            })
        })

        return { state, createProductDropDown }
    }
}
</script>

<style scoped></style>